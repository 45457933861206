import React, { useState } from "react";

import "../ContactUs/ContactUs.css";

const ContactUs = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validation logic
    if (!formData.name || !formData.email || !formData.phone || !formData.message) {
      alert("Please fill in all required fields.");
    } else {
      // If all fields are filled, you can proceed with your action here
      alert("Your query is submitted. We will get in touch soon.");
      // You can also perform further actions like submitting the form data to a backend server
    
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: ""
      });
    
    
    }
  };









  return (
    <div className="ContactUs-div">
      <div className="section ContactUs-Section">
        <div class="container text-center">
          <div class="row">
            <div class="col ContactUs-left ">
              <div className="row ContactUs-Title"> Contact Us</div>
              <div className="row Lets-Title-div">
                <div className="row Lets-Title"> let's get in</div>
                <div className="span-item"> touch!</div>
              </div>
              <div className="row ContactUs-Description">
                {" "}
                Are you interested in supporting our products? Are you planning
                to start a project? Or have you got any questions?{" "}
              </div>
              <div className="row ContactUs-Description">
                {" "}
                Please feel free to get in touch with us
              </div>
              <div className="row">
                <div class="container text-center ">
                  <div class="row mailAndPh-container">
                    <div class="col">
                      <div class="row phBox"> PHONE </div>
                      <div class="row"> +91 9392245045 </div>
                    </div>

                    <div class="col">
                      <div class="row mailBox"> EMAIL </div>
                      <div class="row">hr@aquestglobal.com </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col ContactUs-right">
              <div className="row">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                ></input>
              </div>
              <div className="row">
                <label>Email</label>
                <input
                 type="email" 
                 className="form-control"
                 placeholder="Enter your email..."
                 aria-label="Email"
                 aria-describedby="basic-addon1"
                ></input>
              </div>
              <div className="row">
                <label>Phone</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="+91 ..."
                  aria-label="Username"
                  maxLength="10"
                ></input>
              </div>
              <div className="row">
                <label>Message</label>
                <input
                  type="text"
                  class="form-control input-Message"
                  placeholder="Text..."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                ></input>
              </div>

         

              <div class="form-check TandC">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  checked
                  
                ></input>
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  I have read and agree to the
                  <a href="/terms-and-conditions">
                    Terms & Conditions
                  </a> and <a href="/privacy-policy">Privacy Policy</a>
                </label>
              </div>
              
              <div className="Contact-buttons">
              <button className="getInTouch-btn-two ">Get In Touch</button>
              <button className="getInTouch-btn-three">Schedule a Call</button>
              </div>

            </div> */}


<div className="col ContactUs-right">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter your name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className="row">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter your email..."
                    aria-label="Email"
                    aria-describedby="basicaddon1"
                  />
                </div>
                <div className="row">
                  <label>Phone</label>
                  <input
                    type="number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="+91 ..."
                    aria-label="Username"
                    maxLength="10"
                  />
                </div>
                <div className="row">
                  <label>Message</label>
                  <input
                    type="text"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-control input-Message"
                    placeholder="Text..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className="form-check TandC">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    I have read and agree to the
                    <a href="/terms-and-conditions">
                      Terms & Conditions
                    </a> and <a href="/privacy-policy">Privacy Policy</a>
                  </label>
                </div>
                <div className="Contact-buttons">
                  <button type="submit" className="getInTouch-btn-two">Get In Touch</button>
                  <button type="submit" className="getInTouch-btn-three">Schedule a Call</button>
                </div>
              </form>
            </div>





          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
