import React from "react";
import { Link } from "react-router-dom";
import "../Footer/footer.css";
import LogoAquest2 from "../Assets/AQUEST LOGO-2-fotor-bg-remover-20240219123333.png";


const Footer = () => {
  return (
    <section className="footer-section">
      <div class="container-fluid footer-container ">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
          <div class="col col-sm-12 col-m-6 4 footer-links ">
            {" "}
            <Link className="RemovingLinkStyles" to="/">
            <img className="Footer-Logo" src={LogoAquest2} alt="LogoAquest"/>
  
            </Link>

            <div className="footer-section-div">
              <div className="footerDescription">
                Aquest Solutions is a software development team that creates
                unique IT startups and implements top-notch outsource projects
              </div>

              <div className="row">
                <div className="Nav-SocialMediaIcons">
                  {/* <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22" cy="22" r="21.5" stroke="white" />
                    <path
                      d="M28.4139 14.9147L13.2449 20.7642C12.2097 21.18 12.2157 21.7575 13.055 22.015L16.9495 23.2299L25.9602 17.5447C26.3863 17.2855 26.7755 17.4249 26.4556 17.709L19.1551 24.2976H19.1534L19.1551 24.2985L18.8865 28.3127C19.28 28.3127 19.4537 28.1322 19.6744 27.9192L21.5661 26.0797L25.5008 28.9861C26.2263 29.3856 26.7473 29.1803 26.9278 28.3145L29.5107 16.1416C29.7751 15.0816 29.1061 14.6016 28.4139 14.9147Z"
                      fill="white"
                    />
                  </svg>
                   */}

                  <svg
                    width="44"
                    height="44"
                    style={{
                      border: "0.8px solid black",
                      borderRadius: "50%", cursor:"pointer"
                    }}
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22" cy="22" r="22" fill="white" />
                    <path
                      d="M18.249 14.9926C18.2488 15.468 18.0597 15.9238 17.7234 16.2598C17.3871 16.5958 16.9311 16.7844 16.4557 16.7842C15.9803 16.784 15.5244 16.5949 15.1884 16.2586C14.8524 15.9222 14.6638 15.4662 14.6641 14.9908C14.6643 14.5154 14.8534 14.0596 15.1897 13.7236C15.526 13.3876 15.9821 13.199 16.4575 13.1992C16.9329 13.1995 17.3887 13.3885 17.7247 13.7249C18.0607 14.0612 18.2493 14.5172 18.249 14.9926ZM18.3028 18.1115H14.7178V29.3326H18.3028V18.1115ZM23.9671 18.1115H20.4V29.3326H23.9313V23.4442C23.9313 20.164 28.2063 19.8592 28.2063 23.4442V29.3326H31.7465V22.2253C31.7465 16.6955 25.419 16.9016 23.9313 19.6172L23.9671 18.1115Z"
                      fill="#131414"
                    />
                  </svg>
                  
                </div>
              </div>
            </div>
          </div>

          <div class="col col-sm-12 col-m-6 footer-links ">
            <div className=" footer-titles">QUICK LINKS </div>

            <div class="footer-links">
              <div className="col-sm-12 footer-link">
                <Link className="RemovingLinkStyles" to="/">
                  Home
                </Link>
              </div>
              <div className="col-sm-12 footer-link">
                <Link className="RemovingLinkStyles" to="/services">
                  Services
                </Link>
              </div>
              <div className="col-sm-12 footer-link">
                <a href="#faqs" className="RemovingLinkStyles">
                  FAQs
                </a>
                {/* <Link className="RemovingLinkStyles" to="/faq">F.A.Q</Link> */}
              </div>
              <div className="col-sm-12 footer-link">
                <Link className="RemovingLinkStyles" to="/about">
                  About Us
                </Link>
              </div>
            </div>
          </div>

          <div class="col col-sm-12  col-m-6 ">
            <div className=" footer-titles ">OUR SERVICES</div>
            <div className="footer-section-div footer-links footer-section-two ">
              <div className="col-sm-12 footer-link">
                Web Application Development
              </div>
              <div className="col-sm-12 footer-link">
                Custom Software Development
              </div>
              <div className="col-sm-12 footer-link">Machine Learning & AI</div>
              <div className="col-sm-12 footer-link">Software Testing & QA</div>
              <div className="col-sm-12 footer-link">BPO Chat Process</div>
            </div>
          </div>

          <div class="col-sm-12 col-m-6 footer-links">
            <div className=" footer-titles  ">CONTACT US</div>
            <div className="footer-section-div ">
              <div className="col-sm-12 ">+91 9392245045</div>
              <div className="col-sm-12 ">hr@aquestglobal.com</div>
            </div>
          </div>
        </div>
        <hr></hr>

        <div className="TandC-body">
          <div className="TandC-Container">
            <div className="AllRightsDiv">
              © Aquest Global Solutions | All Rights Reserved. 2024 - 2025
            </div>
            <div className="TandC-Div">
              <Link className="RemovingLinkStyles" to="tandC">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
