import React from "react";
import "./WhyChooseUs.css";
import ContinuousImprovement from "../Assets/continuous-improvement-50.png";
import communicationImg from "../Assets/communication-50.png";
import innovationimg from "../Assets/1087902 1.png";
import { Slide, Zoom } from "react-awesome-reveal";

const WhyChooseUs = () => {
  return (
    <div className="WhyChooseUs-Section ">
      <Slide direction="down">
        <div className="row WhyChooseUs-MainTitle ">
          WHY CHOOSE US
          <p className="WhyChooseUs-SubTitle">
            {" "}
            <span id="spanElement">AQUEST GLOBAL SOLUTIONS </span>
          </p>
        </div>
      </Slide>

      <div className="WhyChooseUs-Container">
        <div class="row row-cols-1 row-cols-md-3 g-4">
          <Zoom>
            <div class="col card--WhyChooseUs">
              <div class="card-body-WhyChooseUs h-100">
                <svg
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.5464 45.4125C21.1484 48.0812 18.9609 52.2229 18.9609 56.875V61.25H10.2109C7.7901 61.25 5.83594 59.2958 5.83594 56.875V53.9583C5.83594 48.3146 10.4005 43.75 16.0443 43.75H18.9609C21.0318 43.75 22.9422 44.3625 24.5464 45.4125Z"
                    fill="#131414"
                  />
                  <path
                    d="M52.5 43.75C56.1244 43.75 59.0625 40.8119 59.0625 37.1875C59.0625 33.5631 56.1244 30.625 52.5 30.625C48.8756 30.625 45.9375 33.5631 45.9375 37.1875C45.9375 40.8119 48.8756 43.75 52.5 43.75Z"
                    fill="#131414"
                  />
                  <path
                    d="M17.5 43.75C21.1244 43.75 24.0625 40.8119 24.0625 37.1875C24.0625 33.5631 21.1244 30.625 17.5 30.625C13.8756 30.625 10.9375 33.5631 10.9375 37.1875C10.9375 40.8119 13.8756 43.75 17.5 43.75Z"
                    fill="#131414"
                  />
                  <path
                    d="M64.1635 53.9583V56.875C64.1635 59.2958 62.2094 61.25 59.7885 61.25H51.0385V56.875C51.0385 52.2229 48.851 48.0812 45.4531 45.4125C47.0573 44.3625 48.9677 43.75 51.0385 43.75H53.9552C59.599 43.75 64.1635 48.3146 64.1635 53.9583Z"
                    fill="#131414"
                  />
                  <path
                    d="M34.9974 45.2077C39.4272 45.2077 43.0182 41.6166 43.0182 37.1869C43.0182 32.7571 39.4272 29.166 34.9974 29.166C30.5676 29.166 26.9766 32.7571 26.9766 37.1869C26.9766 41.6166 30.5676 45.2077 34.9974 45.2077Z"
                    fill="#131414"
                  />
                  <path
                    d="M36.4583 45.209H33.5417C27.109 45.209 21.875 50.4429 21.875 56.8757V61.2507C21.875 63.6627 23.8379 65.6257 26.25 65.6257H43.75C46.1621 65.6257 48.125 63.6627 48.125 61.2507V56.8757C48.125 50.4429 42.891 45.209 36.4583 45.209Z"
                    fill="#131414"
                  />
                  <path
                    d="M56.146 24.3302L52.6271 25.5814C51.6281 25.9358 50.5854 25.1789 50.6146 24.1187L50.7167 20.3854L48.4388 17.425C47.7927 16.585 48.1908 15.3585 49.2073 15.0581L52.7904 14.0022L54.9021 10.9208C55.5015 10.0472 56.7906 10.0472 57.39 10.9208L59.5017 14.0022L63.0848 15.0581C64.1013 15.357 64.4994 16.585 63.8533 17.425L61.5754 20.3854L61.6775 24.1187C61.7067 25.1775 60.6625 25.9358 59.665 25.5814L56.146 24.3302Z"
                    fill="#131414"
                  />
                  <path
                    d="M13.857 24.3302L10.338 25.5814C9.33907 25.9358 8.29637 25.1789 8.32553 24.1187L8.42762 20.3854L6.1497 17.425C5.50366 16.585 5.90178 15.3585 6.91824 15.0581L10.5014 14.0022L12.613 10.9208C13.2124 10.0472 14.5016 10.0472 15.1009 10.9208L17.2126 14.0022L20.7957 15.0581C21.8122 15.357 22.2103 16.585 21.5643 17.425L19.2864 20.3854L19.3884 24.1187C19.4176 25.1775 18.3734 25.9358 17.3759 25.5814L13.857 24.3302Z"
                    fill="#131414"
                  />
                  <path
                    d="M35.0018 21.0495L30.8295 22.5326C29.6453 22.954 28.4086 22.0542 28.4436 20.7986L28.5647 16.3726L25.8639 12.8624C25.0982 11.8663 25.5707 10.4124 26.7753 10.0565L31.0234 8.80383L33.5274 5.1507C34.2376 4.11383 35.7674 4.11383 36.4776 5.1507L38.9816 8.80383L43.2297 10.0565C44.4343 10.4124 44.9068 11.8663 44.1411 12.8624L41.4403 16.3726L41.5614 20.7986C41.5964 22.0542 40.3582 22.954 39.1755 22.5326L35.0018 21.0495Z"
                    fill="#131414"
                  />
                </svg>

                <div class="card-body">
                  <p class="card-title-WhyChooseUs">Vast Experience</p>
                  <p class="card-text-WhyChooseUs">
                    Over 5 years of experience in software development, covering
                    a variety of business domains
                  </p>
                </div>
              </div>
            </div>
          </Zoom>

          <Zoom>
            <div class="col card--WhyChooseUs">
              <div class="card-body-WhyChooseUs h-100">
                <svg
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_422_10533)">
                    <path
                      d="M12.3269 42.7949L27.6497 27.4721L31.3098 31.1363C33.0354 32.8707 35.3323 33.8263 37.7767 33.8263C40.2211 33.8263 42.518 32.8707 44.2444 31.1356C47.7948 27.5674 47.7948 21.7618 44.2444 18.1936L38.5057 12.4396L41.3564 9.9326C48.5273 3.6486 59.4347 4.3676 65.7187 11.5385C72.0027 18.7094 71.2837 29.6168 64.1128 35.9008L60.017 39.6863L55.47 35.1393C52.8245 32.4938 48.5199 32.4938 45.8745 35.1393C44.6129 36.4008 43.9109 38.0721 43.8892 39.8527C42.1809 39.8746 40.4795 40.5342 39.179 41.8347C37.8779 43.136 37.2181 44.8387 37.197 46.5478C35.4877 46.5689 33.785 47.2287 32.4838 48.5299C29.8384 51.1755 29.8383 55.48 32.4836 58.1255L36.1254 61.7672L33.0455 64.6137C30.9444 66.455 27.7484 66.2443 25.907 64.1431C24.0657 62.042 24.2764 58.8459 26.3776 57.0047C24.2765 58.8461 21.0805 58.6354 19.2393 56.5341C17.3979 54.4331 17.6086 51.237 19.7099 49.3958L19.7096 49.3955C17.6085 51.2368 14.4124 51.0262 12.5711 48.9249C10.9932 47.1243 11.0845 44.8511 12.3269 42.7949Z"
                      fill="#131414"
                    />
                    <path
                      d="M5.05649 9.18932C11.7986 2.44731 22.7296 2.44731 29.4717 9.18932L41.3381 21.0871C42.7552 22.5115 43.1905 24.7039 42.4431 26.5657C41.6299 28.5912 39.5355 29.8881 37.359 29.7074C36.1589 29.6077 35.0283 29.0685 34.1852 28.2106L27.6525 21.6705L10.3874 38.9354L5.05663 33.6044C-1.68552 26.8624 -1.68552 15.9313 5.05649 9.18932Z"
                      fill="#131414"
                    />
                    <path
                      d="M54.628 47.6853L48.7782 41.8355C48.2714 41.3287 47.9922 40.6548 47.9922 39.938C47.9922 39.2212 48.2714 38.5474 48.7782 38.0405C49.3013 37.5174 49.9887 37.2559 50.6757 37.2559C51.363 37.2559 52.05 37.5174 52.5732 38.0406L58.4229 43.8903C59.4651 44.9325 59.4684 46.6255 58.4341 47.6729C58.417 47.6897 58.3994 47.7057 58.3821 47.7222C57.8801 48.2046 57.2237 48.4713 56.5254 48.4713C55.8087 48.4713 55.1348 48.1921 54.628 47.6853Z"
                      fill="#131414"
                    />
                    <path
                      d="M47.9325 54.38L42.0827 48.5303C41.5759 48.0233 41.2969 47.3495 41.2969 46.6327C41.2969 45.9159 41.5761 45.2422 42.0827 44.7352C42.5897 44.2284 43.2634 43.9492 43.9803 43.9492C44.6971 43.9492 45.371 44.2284 45.8778 44.7352L51.7276 50.585C52.2344 51.092 52.5136 51.7657 52.5136 52.4825C52.5136 53.1954 52.2371 53.8653 51.7356 54.3713C51.7245 54.3822 51.7131 54.3925 51.702 54.4033C50.6536 55.4248 48.9706 55.418 47.9325 54.38Z"
                      fill="#131414"
                    />
                    <path
                      d="M41.236 61.0754L35.3862 55.2257C34.3401 54.1794 34.3399 52.4768 35.3862 51.4307C35.8932 50.9238 36.5669 50.6445 37.2838 50.6445C38.0006 50.6445 38.6745 50.9237 39.1813 51.4305L45.0311 57.2803C45.5379 57.7873 45.8171 58.461 45.8171 59.1778C45.8171 59.8926 45.5392 60.5646 45.035 61.071C45.0296 61.0764 45.024 61.0814 45.0185 61.0867C43.9711 62.1208 42.2784 62.1176 41.236 61.0754Z"
                      fill="#131414"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_422_10533">
                      <rect width="70" height="70" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <div class="card-body">
                  <p class="card-title-WhyChooseUs">Customer Focus</p>
                  <p class="card-text-WhyChooseUs">
                    We strive to bring the greatest value for our client through
                    the best-quality and on-time work
                  </p>
                </div>
              </div>
            </div>
          </Zoom>

          <Zoom>
            <div class="col card--WhyChooseUs">
              <div class="card-body-WhyChooseUs h-100">
                <svg
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M57.5568 49.3534L49.2253 49.3537C47.1001 52.6627 44.2673 55.4635 40.9375 57.5586H57.5568V63.7089L69.9982 53.457L57.5568 43.2031V49.3534Z"
                    fill="#131414"
                  />
                  <path
                    d="M26.9526 57.5509C39.3384 57.4663 49.3555 47.4061 49.3555 35C49.3555 23.2338 40.3438 13.5822 28.8477 12.5448V6.28906L16.543 16.543L28.8477 26.7948V20.8518C35.7861 21.8576 41.1523 27.7874 41.1523 35C41.1523 42.9148 34.7117 49.3555 26.7969 49.3555H0V57.5586H26.9619C26.9578 57.5561 26.9566 57.5534 26.9526 57.5509Z"
                    fill="#131414"
                  />
                  <path
                    d="M16.7782 45.253C14.1078 42.6444 12.4375 39.0175 12.4375 34.9991C12.4375 30.2327 14.7982 26.0309 18.3853 23.4186L11.9408 18.0488C7.22619 22.1835 4.23438 28.2357 4.23438 34.9991C4.23438 38.7048 5.18594 42.1686 6.76928 45.253H16.7782Z"
                    fill="#131414"
                  />
                </svg>

                <div class="card-body">
                  <p class="card-title-WhyChooseUs">Full Agility</p>
                  <p class="card-text-WhyChooseUs">
                    Adjusting to your preferences and requirements in order to
                    maximize the efficiency of cooperation
                  </p>
                </div>
              </div>
            </div>
          </Zoom>

          <Zoom>
            <div class="col card--WhyChooseUs">
              <div class="card-body-WhyChooseUs h-100">
                <img
                  style={{ width: "70px" }}
                  src={communicationImg}
                  alt="logo-innovation"
                ></img>

                <div class="card-body">
                  <p class="card-title-WhyChooseUs">
                    Transparent Communication
                  </p>
                  <p class="card-text-WhyChooseUs">
                    We believe in open and honest communication throughout the
                    project lifecycle, ensuring clarity, alignment, and trust
                    between our team and yours.
                  </p>
                </div>
              </div>
            </div>
          </Zoom>

          <Zoom>
            <div class="col card--WhyChooseUs">
              <div class="card-body-WhyChooseUs h-100">
                <img
                  style={{ width: "70px" }}
                  src={ContinuousImprovement}
                  alt="logo-innovation"
                ></img>

                <div class="card-body">
                  <p class="card-title-WhyChooseUs">Continuous Improvement</p>
                  <p class="card-text-WhyChooseUs">
                    We are committed to ongoing learning and improvement,
                    regularly seeking feedback and implementing lessons learned
                    to enhance our processes, skills, and deliverables,
                    ultimately providing better outcomes for our clients.
                  </p>
                </div>
              </div>
            </div>
          </Zoom>

          <Zoom>
            <div class="col card--WhyChooseUs">
              <div class="card-body-WhyChooseUs h-100">
                <img src={innovationimg} alt="logo-innovation"></img>

                <div class="card-body">
                  <p class="card-title-WhyChooseUs">Innovative Solutions</p>
                  <p class="card-text-WhyChooseUs">
                    Utilizing cutting-edge technologies and creative
                    problem-solving to deliver unique and effective solutions
                    tailored to your specific needs
                  </p>
                </div>
              </div>
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
