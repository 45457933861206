import React from "react";
import "../ContactUs/ContactUsCard.css";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";


const ContactUsCard = () => {
  return (
    <div className="Section ContactUsSection">
    
       <Zoom>
       <div class="container-fluid text-center">
          <div class="row ContactUsCard">
            <div class="col ContactUsCard-left order-md-first order-first">
              <div className="row WeCanHelp"> WE CAN HELP! </div>
              <div className="row ContactUsToday">CONTACT US TODAY</div>
              <div className="row ContactUsCard-Description">
                Aquest Global Solutions takes a different approach to IT-service
                delivery, primarily focusing on maximum value for a reasonable
                price. Our developers have more than 5 years of commercial
                experience with dozens of successfully delivered projects.
              </div>
            </div>

            <div class="col getInTouch-btnContainer ">
            <Link to="/contactUs">
            <button className="getInTouch-btn">Get In Touch</button>
            </Link>
              
            </div>
   
        </div>
      </div>
       </Zoom>
    </div>
  );
};

export default ContactUsCard;
