import React, { useState } from "react";
import "../Faq/Faq.css";
import { Zoom} from "react-awesome-reveal";


const Faq = () => {
  const [selected, setSelected] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const FAQtoggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  

  const FAQquestions = [
    {
      question: "What services does Aquest Global Solutions provide?",
      answer:
        "Aquest Global Solutions offers a range of services including web application development, mobile application development,custom software development, machine learning & AI solutions, software testing & QA, and more",
    },
    {
      question:
        "What sets Aquest Global Solutions apart from other software development companies?",
      answer:
        "Aquest Global Solutions distinguishes itself with over 5 years of experience in software development across various business domains, a customer-focused approach emphasizing quality and timely delivery, full agility to adapt to client preferences, and transparent communication throughout the project lifecycle.",
    },
    {
      question: "What technologies does Aquest Global Solutions utilize?",
      answer:
        "Aquest Global Solutions employs a comprehensive stack of popular technologies, allowing its specialists to implement innovative ideas effectively.",
    },
    {
      question: "How can I get in touch with Aquest Global Solutions?",
      answer:
        "You can contact Aquest Global Solutions by phone at +91 9392245045 or via email at hr@aquestglobal.com. Alternatively, you can use the contact form on their website",
    },
    // Additional questions to show on "Show More"
    {
      question:
        "Does Aquest Global Solutions provide web application development services",
      answer:
        "Yes, Aquest Global Solutions specializes in creating modern, robust web applications tailored to meet various business needs.",
    },
    {
      question:
        "Does Aquest Global Solutions offer machine learning and AI solution",
      answer:
        "Yes, Aquest Global Solutions provides sophisticated self-taught enterprise solutions utilizing machine learning and AI technologies.",
    },
    {
      question:
        "What is Aquest Global Solutions' approach to software testing and quality assurance?",
      answer:
        "Aquest Global Solutions ensures meticulous quality control to guarantee flawless operations in its software solutions, emphasizing the importance of delivering high-quality products to its clients.",
    },
  ];

  return (
    <div className="section" id="faqSection">
      <div className="Faq-container-main" id="faqs">
        <div className="FaqMainHeadings">
          <div className="FaqMainHeading">Frequently Asked Questions</div>
          <div className="FaqSubHeading">Most Popular Questions</div>
        </div>

        <div className="section" id="faqSection">
        <Zoom>
        <div className="accordian">
            {FAQquestions.slice(0, showMore ? FAQquestions.length : 4).map(
              (item, i) => (
                <div className="item" key={i}>
                  <div className="FAQ-title" onClick={() => FAQtoggle(i)}>
                    <div className="faqQuestion">{item.question}</div>
                    <div className="p-4 uuu" id="expandToggleOne">
                      {selected === i ? "-" : "+"}
                    </div>
                  </div>
                  <div
                    className={
                      selected === i ? "FAQ-content show" : "FAQ-content"
                    }
                  >
                    <p>{item.answer}</p>
                  </div>
                </div>
              )
            )}

            <center>
              <button
                className="FaqMoreBtn"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show Less" : "Show More"}
              </button>
            </center>
          </div>
        </Zoom>
        

        </div>
      </div>
      </div>
  );
};

export default Faq;
