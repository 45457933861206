import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import MarqueeComponent from "../Components/Marquee/Marquee.js";
import Faq from "../Components/Faq/Faq.js";
import "./Homepage.css";
import ContactUs from "../Components/ContactUs/ContactUs.js";
import CardsForHome from "./CardsForHome/CardsForHome.js";
import WhyChooseUs from "./WhyChooseUs/WhyChooseUs.js";
import Clients from "./Clients/Clients.js";
import HomeHeadImg from "../Components/Assets/HomeHead.png";
import { Slide, Zoom } from "react-awesome-reveal";

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate for navigation

  // Effect to scroll to the FAQ section if scrollToFAQs is true
  useEffect(() => {
    if (location.state && location.state.scrollToFAQs) {
      const faqSection = document.getElementById("faqSection");
      if (faqSection) {
        faqSection.scrollIntoView({ behavior: "smooth" });
        // Clear the state after scrolling
        navigate("/", { replace: true });
      }
    }
  }, [location.state, navigate]); // Include navigate in the dependencies array



  return (
    <div>
      <div className="text-center">
        <div className="HomeHead">
          <div className="col-md-5 HomeLeft order-md-first order-first">
            <Slide direction="down">
              <div
                className="Home-header"
                style={{
                  padding: "10px 15px",
                  marginTop: "30px",
                  background: "#3C6373",
                  borderRadius: "10px",
                  color: "#FFFFFF",
                  display: "inline-block", // Set display to inline-block
                }}
              >
                AQUEST GLOBAL SOLUTIONS
              </div>
            </Slide>
            <Zoom>
              <div className="Home-title">
                Empowering Progress Through Seamless Software Solutions
              </div>
            </Zoom>

            <Slide>
              <div className="HomeHead-description">
                Experienced team of innovators and developers bringing new
                products to life solutions
              </div>
            </Slide>

            <div className="Contact-buttons">
              <Slide direction="up">
                <Link to="/contactUs">
                  <button className="getInTouch-btn-four">Get In Touch</button>
                </Link>

                <Link to="/services">
                  <button className="getInTouch-btn-five">Services</button>
                </Link>

              </Slide>
            </div>
          </div>
          <div className="col-md-7 col-lg-7 ServicesRight">
            <img
              className="HomeHeadImg largehome-img"
              src={HomeHeadImg}
              alt="Services Large"
            />
          </div>
        </div>
      </div>

      <WhyChooseUs />
      <CardsForHome />
      <MarqueeComponent />
      <Clients />
      <Faq />
      <ContactUs />
    </div>
  );
};

export default HomePage;
