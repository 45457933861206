import React from "react";
import "./Clients.css";
import { Marquee } from "@devnomic/marquee";
import "@devnomic/marquee/dist/index.css"; // if you copy ala shadcn, no need import css.

import UnionSys from "../Assets/UnionSys.jpeg";
import Cloudjune from "../Assets/Cloudjune.jpeg";
import Saveo from "../Assets/Saveo.jpeg";
import Whole9yards from "../Assets/Whole9yards.png";
import Xperteez from "../Assets/Xperteez.jpeg";
import Zepto from "../Assets/Zepto.png";
import tarangan from "../Assets/tarangan.jpeg";
import vcosmos from "../Assets/vcosmos.jpeg";
import franklin from "../Assets/franklin-sports-inc-logo-vector.png";

const Clients = () => {
  return (
    <>
      <div className="Section Clients-section">
        <div className="container-fluid text-center Clients-Container">
          <div className="col col-lg-4 OurClients-Headings">
            <div className=" OurClients-Heading">Our Clients</div>
            <div className=" OurClients-SubHeading">
              Explore the trusted partners powering our success - from
              innovative startups to industry-leading enterprises, our diverse
              portfolio showcases the brands we proudly collaborate with to
              deliver excellence.
            </div>
          </div>

          <Marquee className="col col-lg-8  OurClients-Images">
            {/* <div className="MarqueeForClients-Card"> <img src={Tensor} alt=""></img></div> */}
            <div className="MarqueeForClients-Card">
              {" "}
              <img src={UnionSys} alt=""></img>
            </div>
            <div className="MarqueeForClients-Card">
              {" "}
              <img src={Cloudjune} alt=""></img>
            </div>
            <div className="MarqueeForClients-Card">
              {" "}
              <img src={Saveo} alt=""></img>
            </div>
            <div className="MarqueeForClients-Card">
              {" "}
              <img src={Whole9yards} alt=""></img>
            </div>
            <div className="MarqueeForClients-Card">
              {" "}
              <img src={Xperteez} alt=""></img>
            </div>
            <div className="MarqueeForClients-Card">
              {" "}
              <img src={Zepto} alt=""></img>
            </div>
            <div className="MarqueeForClients-Card">
              {" "}
              <img src={tarangan} alt=""></img>
            </div>
            <div className="MarqueeForClients-Card">
              {" "}
              <img src={vcosmos} alt=""></img>
            </div>
            <div className="MarqueeForClients-Card">
              {" "}
              <img src={franklin} alt=""></img>
            </div>
          </Marquee>
        </div>
      </div>
    </>
  );
};

export default Clients;
