import React from "react";
import Target from "../Assets/target 1.png";
import './OurMission.css'

const OurMision = () => {
  return (
    <>
      <div className="OurMission-Section">
        <div class="container-fluid OurMission-Container">
          <div class="col text-center OurMission-Title">Our Mission</div>
          <div class="row OurMission-Matter ">
            <div class="col-md-4 OurMission-SubTitl">
              Project implementation is not solely about writing code but is a
              deep understanding of the fundamental idea and all the
              complementary processes behind it.
            </div>
            <div class="col-md-4 p-2">
              <img src={Target} alt=""></img>
            </div>
            <div class="col-md-4 OurMission-SubTitl">
              Therefore, our mission is to create top-tier software products
              that have business value and are as useful as possible for our
              partners.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurMision;
