import React from "react";
import "./About.css";
import { Link } from "react-router-dom";
import ContactUs from "./ContactUs/ContactUs.js";
import Clients from "./Clients/Clients.js";
import OurValues from "../Components/OurValues/OurValues.js";
import OurMission from "../Components/OurMission/OurMision.js";
import AboutUsImg from "../Components/Assets/AboutusImg.png";
import WhyChooseUs from "../Components/WhyChooseUs/WhyChooseUs.js";
import { Slide, Zoom } from "react-awesome-reveal";
const AboutUs = () => {
  return (
    <>
      <div>
        <div>
          <div className="HomeHead">
            <div className="col-md-5 Aboutleft order-md-first order-first">
              <Slide direction="down">
                <div
                  className="About-header"
                  style={{
                    padding: "10px 15px",
                    marginTop: "30px",
                    background: "#3C6373",
                    borderRadius: "10px",
                    color: "#FFFFFF",
                    display: "inline-block", // Set display to inline-block
                  }}
                >
                  AboutUs
                </div>
              </Slide>

              <Zoom>
                <div className="About-title">
                  We are a Tech Partner for Startups
                </div>
              </Zoom>
              <Slide>
                <div className="AboutHead-description">
                  A company of dedicated and experienced individuals, aimed at
                  creating and delivering the ideality of software solutions
                </div>
              </Slide>

              <Slide direction="up">
                <div className="Contact-buttons">
                  <Link to="/contactUs">
                    <button className="getInTouch-btn-four">
                      Get In Touch
                    </button>
                  </Link>
                </div>
              </Slide>
            </div>
            <div className="col-sm-11 col-md-6 col-lg-7 AboutRight">
              <img
                className="AboutHeadImg largeAbout-img"
                src={AboutUsImg}
                alt="Services Large"
              />
            </div>
          </div>

          <div className="AboutUs-Matter">
            <div className="AboutUs-SubDivMatter">
              Aquest Global Solutions stands at the forefront of the software
              solutions industry, with its headquarters strategically located in
              [Location]. At the heart of our operations lies an innovative Full
              Service Provider (FSP) model, designed to cater to the diverse
              needs of our esteemed clientele. With a sharp focus on delivering
              comprehensive software development services, we pride ourselves on
              our ability to adapt and evolve in response to the ever-changing
              landscape of technology.
            </div>
            <div className="AboutUs-SubDivMatter">
              Since our inception, we have been driven by a relentless pursuit
              of excellence. We thrive on challenging industry norms and
              surpassing expectations with our robust solutions that not only
              meet but exceed our clients' requirements. Our commitment to
              excellence is ingrained in every aspect of our work, from the
              initial ideation phase to the final delivery of the product.
            </div>
            <div className="AboutUs-SubDivMatter">
              As a company, we are fueled by a vision to become the foremost IT
              partner for our clients. We recognize that each of our clients is
              unique, with distinct needs and challenges. Therefore, we place
              great emphasis on fostering close collaborations and understanding
              their specific requirements. Through this collaborative approach,
              we are able to develop bespoke strategies and technologies that
              are tailor-made to address their individual needs.
            </div>
            <div className="AboutUs-SubDivMatter">
              Central to our ethos is our team of passionate professionals who
              are driven by an insatiable desire to innovate and push the
              boundaries of what is possible in the software industry. Their
              expertise spans a wide range of disciplines, including design,
              development, and implementation, allowing us to deliver
              cutting-edge solutions that are both innovative and impactful.
            </div>
            <div className="AboutUs-SubDivMatter">
              At Aquest Global Solutions, we believe in the power of technology
              to drive positive change and transform businesses. We are
              committed to staying ahead of the curve and embracing emerging
              technologies to ensure that our clients remain competitive in
              today's fast-paced digital world. With our unparalleled expertise
              and dedication to excellence, we are confident in our ability to
              deliver exceptional results and exceed the expectations of our
              clients, every step of the way.
            </div>
          </div>

          <WhyChooseUs />
          <OurMission />
          <OurValues />
          <Clients />
          <ContactUs />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
