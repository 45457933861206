import React, { useEffect } from "react"; // Import useEffect
import "./Services.css";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation and useNavigate
import LargeServicesImg from "../Assets/ServicesImg.png";
import SmallServicesImg from "../Assets/ServicesHeadSmallScreen.png";
import WhatWeOffer from "./WhatWeOffer.js";
import ContactUsCard from "../ContactUs/ContactUsCard.js";
import ContactUs from "../ContactUs/ContactUs.js";
import Clients from "../Clients/Clients.js";
import Marquee from "../Marquee/Marquee.js";
import ServicesSwiper from "./ServicesSwiper.js";
import HowWeWork from "./HowWeWork.js";
import { Slide, Zoom } from "react-awesome-reveal";

const Services = () => {
  const location = useLocation(); // Use useLocation to get the location object
  const navigate = useNavigate(); // Use useNavigate to get the navigate function


  return (
    <>
      <div className="ServicsSection section">
        <div className=" text-center ">
          <div className=" ServicesHead">
            {/* For screens larger than 991px, the order remains the same */}
            <div className="col-md ServicesLeft order-md-first order-first">
              <Slide direction="down">
                <div
                  className="col services-header"
                  style={{
                    padding: "10px 15px",
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "160px",
                    height: "40px",
                    background: "#3C6373",
                    borderRadius: "10px",
                    color: "#FFFFFF",
                  }}
                >
                  What we offer
                </div>
              </Slide>

              <Zoom>
                <div
                  className="col services-title"
                  style={{
                    fontWeight: 700,
                    fontSize: "40px",
                    lineHeight: "73px",
                  }}
                >
                  Services
                </div>
              </Zoom>
              <Slide>
                <div className="col services-description">
                  A team of highly-skilled professionals is ready to implement
                  your vision in code using the latest and most efficient
                  software solutions
                </div>
              </Slide>
              <Slide direction="up">
                <div className="col">
                  <Link to="/contactUs">
                    <button className="getInTouch-btn">Get In Touch</button>
                  </Link>
                </div>
              </Slide>
            </div>

            {/* For screens smaller than 991px, the order is reversed */}
            <div className="col-md ServicesRight">
              <img
                className="ServicesHeadImg large-img"
                src={LargeServicesImg}
                alt="Services Large"
              />
              <img
                className="ServicesHeadImg small-img"
                src={SmallServicesImg}
                alt="Services Small"
              />
            </div>
          </div>
        </div>
      </div>

      <ServicesSwiper />

      <WhatWeOffer />

      <HowWeWork />

      <Marquee />

      <ContactUsCard />

      <Clients />

      <ContactUs />
    </>
  );
};

export default Services;
