import React, { useState } from "react";
import { Link } from "react-scroll"; // Import Link from react-scroll

import "./WhatWeOffer.css";
import WebApplicationDev from "../Assets/WebApplicationDev.png";
import AiImg from "../Assets/Ai.png";
import CSAimg from "../Assets/CustomSoftwareDev.png";
import STQAimg from "../Assets/SoftwareTesting.png";
import { Slide } from "react-awesome-reveal";

const WhatWeOffer = () => {
  const toggleCollapse = () => {
    const contentTwo = document.getElementById("collapseContent");
    const button = document.getElementById("collapseButton");

    if (
      contentTwo.style.display === "none" ||
      contentTwo.style.display === ""
    ) {
      contentTwo.style.display = "block";
      button.innerText = "Show Less";
    } else {
      contentTwo.style.display = "none";
      button.innerText = "Show More";
    }
  };

  return (
    <>
      <div>
        <Slide direction="down">
          <div className="WhatWeOfffer-Titles">
            <div class="row WhatWeOfffer-TitlesOne">Services</div>
            <div class="row WhatWeOfffer-TitlesTwo">
              What We{" "}
              <div className="col" id="spanElement">
                Offer
              </div>
            </div>
          </div>
        </Slide>

        <div className="OffersDiv">
          <section>
            <div class="container-fluid text-center WebDevContainer">
              <div class="row">
                <div class="col-md  ServiceOffer-Left order-md-first order-first">
                  <img className="ServicesImg" src={WebApplicationDev}></img>
                </div>
                <div class="col-md ServicesOffer-Right">
                  <div class="row Content-Title">
                    Web Application Development
                  </div>
                  <div class="row Content-DescriptionOne">
                    Our team is highly skilled with full-stack development of
                    any web-related software, whether it is static, dynamic,
                    portal web apps, or Content Management Systems (CMS).{" "}
                  </div>
                  <div class="row Content-DescriptionOne">
                    We design and create stunning applications for anyone, from
                    startups to large corporations, with such up-to-date
                    features and qualities:{" "}
                  </div>
                  <div class="row Content-Lists">
                    <ul>
                      <li>
                        {" "}
                        <span>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="11" cy="11" r="11" fill="#4BAAD0" />
                            <path
                              d="M15.6693 7.5L9.2526 13.9167L6.33594 11"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        Heavy-load support
                      </li>
                      <li>
                        {" "}
                        <span>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="11" cy="11" r="11" fill="#4BAAD0" />
                            <path
                              d="M15.6693 7.5L9.2526 13.9167L6.33594 11"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        Microservice architecture
                      </li>
                      <li>
                        {" "}
                        <span>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="11" cy="11" r="11" fill="#4BAAD0" />
                            <path
                              d="M15.6693 7.5L9.2526 13.9167L6.33594 11"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        Reactive approach
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="Content-DescriptionTwo" id="collapseContent">
                web application development Our team is highly skilled with
                full-stack development of any web-related software, whether it
                is static, dynamic, portal web apps, or Content Management
                Systems (CMS). We design and create stunning applications for
                anyone, from startups to large corporations, with such
                up-to-date features and qualities: Heavy-load support
                Microservice architecture Reactive approach Web application
                development is a dynamic and ever-evolving field that plays a
                crucial role in shaping our digital world. With the exponential
                growth of the internet and the increasing demand for online
                services, web applications have become an integral part of our
                daily lives. From e-commerce platforms and social networking
                sites to productivity tools and streaming services, web
                applications offer a wide range of functionalities to cater to
                diverse user needs. The process of web application development
                involves several key steps. It begins with thorough planning and
                requirement analysis, where developers collaborate with
                stakeholders to understand their objectives and define the
                application's features and functionalities. This stage lays the
                foundation for the entire development process and ensures that
                the end product aligns with the client's vision. Once the
                planning phase is complete, the development team moves on to
                designing the user interface (UI) and user experience (UX) of
                the web application. This involves creating wireframes, mockups,
                and prototypes to visualize the application's structure and
                navigation flow. Attention is paid to usability, accessibility,
                and responsive design to ensure a seamless experience across
                different devices and platforms. After the design phase, the
                actual coding and development of the web application take place.
                Developers employ various programming languages such as HTML,
                CSS, JavaScript, and frameworks like React, Angular, or Django
                to build the application's front-end and back-end components.
                They also integrate databases, APIs, and other third-party
                services to enhance the application's functionality. Testing and
                quality assurance are vital stages in web application
                development. Rigorous testing is conducted to identify and
                rectify any bugs, errors, or security vulnerabilities. This
                includes unit testing, integration testing, performance testing,
                and user acceptance testing to ensure the application functions
                reliably and provides a smooth user experience. Once the
                development and testing phases are complete, the web application
                is ready for deployment. It is hosted on servers or cloud
                platforms, making it accessible to users worldwide. Continuous
                monitoring, maintenance, and updates are essential to ensure the
                application's performance, security, and scalability. Web
                application development is a multidisciplinary field that
                requires expertise in programming, design, and user experience.
                It empowers businesses and individuals to deliver innovative
                solutions, reach a wider audience, and streamline their
                operations. As technology continues to advance, web application
                development will remain at the forefront of digital innovation,
                shaping the way we interact and engage with the online world.
              </div>

              <button id="collapseButton" onClick={toggleCollapse}>
                {" "}
                Show Less
              </button>
            </div>
          </section>

          <section>
            <div class="container-fluid text-center MLContiner">
              <div class="row">
                <div class="col-md ServicesOffer-Right order-md-first order-first">
                  <div class="row Content-Title">Machine Learning & AI</div>
                  <div class="row Content-DescriptionOne">
                    With a strong background in solving complicated Machine
                    Learning problems, we are ready to build a powerful «brain»
                    for your application, that will efficiently solve
                    complicated and extraordinary tasks, using the following
                    Neural Network technologies:
                  </div>

                  <div class="row Content-Lists">
                    <ul>
                      <li>
                        {" "}
                        <span>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="11" cy="11" r="11" fill="#4BAAD0" />
                            <path
                              d="M15.6693 7.5L9.2526 13.9167L6.33594 11"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        Computer Vision
                      </li>
                      <li>
                        {" "}
                        <span>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="11" cy="11" r="11" fill="#4BAAD0" />
                            <path
                              d="M15.6693 7.5L9.2526 13.9167L6.33594 11"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        Natural Language Processing
                      </li>
                      <li>
                        {" "}
                        <span>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="11" cy="11" r="11" fill="#4BAAD0" />
                            <path
                              d="M15.6693 7.5L9.2526 13.9167L6.33594 11"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        Sequences and Time Series
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md  ServiceOffer-Left ">
                  <img className="ServicesImg" src={AiImg}></img>
                </div>
              </div>

              <div class="Content-DescriptionTwo">
                Our team boasts a robust proficiency in tackling intricate
                Machine Learning challenges, equipped to construct a formidable
                "brain" for your application. We specialize in efficiently
                addressing complex and extraordinary tasks using cutting-edge
                Neural Network technologies. Our expertise spans various domains
                within Machine Learning, including:
                <div className="Content-DescriptionTwoMatter">
                  <span id="SubHeadingContentHeadings">Computer Vision:</span>
                  Leveraging advanced algorithms, we enable machines to
                  interpret and understand the visual world. From image
                  recognition to object detection, our solutions in computer
                  vision bring a new dimension to your application.
                  <span id="SubHeadingContentHeadings">
                    Natural Language Processing (NLP):
                  </span>
                  Our proficiency in NLP empowers your application to comprehend
                  and interpret human language. We develop intelligent chatbots,
                  language translation services, and sentiment analysis tools,
                  enhancing user interaction.
                  <span id="SubHeadingContentHeadings">
                    Sequences and Time Series:
                  </span>
                  Handling sequential data and time series analytics is a
                  distinctive feature of our Machine Learning capabilities.
                  Whether it's predicting future trends or analyzing sequential
                  patterns, our solutions are tailored to meet your specific
                  requirements.
                  <p>
                    Handling sequential data and time series analytics is a
                    distinctive feature of our Machine Learning capabilities.
                    Whether it's predicting future trends or analyzing
                    sequential patterns, our solutions are tailored to meet your
                    specific requirements.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="container-fluid text-center CustomSoftwareDevContainer">
              <div class="row">
                <div class="col-md  ServiceOffer-Left order-md-first order-first">
                  <img className="ServicesImg" src={CSAimg}></img>
                </div>
                <div class="col-md ServicesOffer-Right">
                  <div class="row Content-Title">
                    Custom Software Development
                  </div>
                  <div class="row Content-DescriptionOne">
                    Aquest Global Solutions is where challenging ideas and tasks
                    seamlessly transform into high-end software.
                  </div>
                  <div class="row Content-DescriptionOne">
                    Upgrade your current applications or create something
                    outstanding by applying the latest and greatest design
                    principles and methodologies.
                  </div>
                  <div class="row Content-DescriptionOne">
                    Develop a better user experience, increase performance,
                    automate, secure, or integrate – we are here to implement
                    your ideas.
                  </div>
                  <div class="row Content-DescriptionOne">
                    We use the latest innovative tools and leverage our
                    extensive experience to solve your business challenges of
                    any complexity
                  </div>
                </div>
              </div>

              <div class="Content-DescriptionTwo">
                At Aquest Global Solutions, we specialize in turning challenging
                ideas and complex tasks into sophisticated, high-end software
                solutions. Whether you're looking to enhance existing
                applications or embark on creating something truly exceptional,
                we apply the latest design principles and methodologies to bring
                your vision to life.
              </div>
              <div className="Content-DescriptionTwo">
                <h2 className="text-start" id="SubHeadingContentHeadings">
                  Key Highlights:
                </h2>

                <ul>
                  <li>
                    <span id="SubHeadingContentHeadings">
                      Upgrade with Innovation:
                    </span>
                    Elevate your software to new heights by incorporating the
                    latest and most advanced design principles. Our team thrives
                    on innovation, ensuring your applications are at the
                    forefront of technology.
                  </li>

                  <li>
                    <span id="SubHeadingContentHeadings">
                      Performance Enhancement:
                    </span>
                    Witness a significant boost in performance as we optimize
                    and fine-tune your software. Our experts utilize innovative
                    tools and techniques to enhance speed, efficiency, and
                    overall functionality.
                  </li>

                  <li>
                    <span id="SubHeadingContentHeadings">
                      User Experience Excellence:
                    </span>
                    Craft a superior user experience that captivates and engages
                    your audience. Through meticulous design and user-centric
                    approaches, we ensure your software delivers an unparalleled
                    experience.
                  </li>

                  <li>
                    <span id="SubHeadingContentHeadings">
                      Automation Solutions:
                    </span>
                    Streamline your processes and workflows through tailored
                    automation solutions. Our custom software development caters
                    to your unique business needs, automating tasks for
                    increased efficiency.
                  </li>

                  <li>
                    <span id="SubHeadingContentHeadings">
                      Security Implementation:
                    </span>
                    Safeguard your software with robust security measures. We
                    prioritize the integration of cutting-edge security
                    protocols, protecting your application and user data from
                    potential threats.
                  </li>

                  <li>
                    <span id="SubHeadingContentHeadings">
                      Seamless Integration:
                    </span>
                    Whether it's integrating with existing systems or
                    incorporating new features, our development team ensures
                    seamless integration, preserving the continuity of your
                    business operations.
                  </li>
                </ul>
                <p id="SubHeadingContentHeadings">
                  Innovative Tools and Expertise:
                </p>
                <p>
                  We leverage the latest and most innovative tools, coupled with
                  our extensive experience, to tackle business challenges of any
                  complexity. Our commitment is to deliver solutions that not
                  only meet but exceed your expectations.
                </p>
                <p id="SubHeadingContentHeadings">
                  Your Ideas, Our Implementation::
                </p>
                <p>
                  Have a concept in mind? We're here to turn your ideas into
                  reality. From concept to deployment, our custom software
                  development services are tailored to implement your vision
                  with precision.
                  <br></br>
                  Upgrade, innovate, and excel with Aquest Global Solutions -
                  where your custom software development journey meets
                  excellence.
                </p>
              </div>
            </div>
          </section>

          <section>
            <div class="container-fluid text-center StQaDevContainer">
              <div class="row">
                <div class="col-md ServicesOffer-Right order-md-first order-first">
                  <div class="row Content-Title">
                    Software Testing & Quality Assurance
                  </div>
                  <div class="row Content-DescriptionOne">
                    A perfect user experience and impeccable data security are
                    crucial for a modern application.
                  </div>
                  <div class="row Content-DescriptionOne">
                    That is why our team helps you to find and handle any
                    possible bugs, vulnerabilities, or issues in your product
                    via manual, automated, API, security, or performance
                    testing.
                  </div>
                  <div class="row Content-DescriptionOne">
                    Note that we conduct QA and Software testing for every
                    software we develop, plus we can check your application
                    performance as a separate service
                  </div>
                </div>

                <div class="col-md  ServiceOffer-Left ">
                  <img className="ServicesImg" src={STQAimg}></img>
                </div>
              </div>

              <div class="Content-DescriptionTwo">
                <div className="Content-DescriptionTwoMatter">
                  {/* <h3>Our Testing Expertise:</h3> */}
                  <div className="Content-DescriptionTwoMatter">
                    <h2 id="SubHeadingContentHeadings">
                      Our Testing Expertise:
                    </h2>

                    <ul>
                      <li>
                        <span id="SubHeadingContentHeadings">
                          Manual Testing:
                        </span>
                        Meticulous examination by our skilled testers ensures
                        that every aspect of your application is scrutinized
                        manually, guaranteeing a seamless user journey.
                      </li>

                      <li>
                        <span id="SubHeadingContentHeadings">
                          Automated Testing:
                        </span>
                        Harnessing the power of automation, we deploy
                        cutting-edge tools to conduct efficient and repetitive
                        testing, streamlining the identification and resolution
                        of software glitches.
                      </li>

                      <li>
                        <span id="SubHeadingContentHeadings">API Testing:</span>
                        Our expertise extends to testing the functionality and
                        performance of your application's APIs, ensuring
                        seamless communication and data exchange.
                      </li>

                      <li>
                        <span id="SubHeadingContentHeadings">
                          Security Testing:{" "}
                        </span>
                        We prioritize the safety of your data with rigorous
                        security testing. Identifying and rectifying
                        vulnerabilities, we fortify your application against
                        potential threats, adhering to industry best practices.
                      </li>
                      <li>
                        <span id="SubHeadingContentHeadings">
                          Performance Testing:
                        </span>
                        To guarantee optimal functionality under varying
                        conditions, our team conducts performance testing,
                        assessing your application's responsiveness,
                        scalability, and stability.
                      </li>
                    </ul>
                    <p id="SubHeadingContentHeadings">
                      Separate Performance Checks:
                    </p>
                    <p>
                      It's not just a service; it's a commitment. At every stage
                      of software development, we embed Quality Assurance and
                      Testing, ensuring that your application not only meets but
                      exceeds industry standards.
                    </p>
                    <p id="SubHeadingContentHeadings">Our QA Commitment:</p>
                    <p>
                      Beyond the development phase, we offer standalone
                      performance checks for your application. Our dedicated
                      service ensures that your product continues to perform at
                      its peak, delivering a flawless user experience.
                      <br></br>
                      Choose confidence; choose reliability. Our Software
                      Testing & Quality Assurance services provide the assurance
                      that your application not only meets but exceeds user
                      expectations while maintaining the highest standards of
                      security and performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default WhatWeOffer;
