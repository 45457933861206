import React, { useState, useEffect } from "react";
import "../Components/Navbar.css";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import BootstrapNavbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import LogoAquest2 from "./Assets/AQUEST LOGO-2-fotor-bg-remover-20240219123333.png";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate

function MyNavbar() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate(); // Define navigate here

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClose = () => {
    setShowOffcanvas(false);
  };

  const handleLinkClick = () => {
    setShowOffcanvas(false); // Close the offcanvas when any navigation link is clicked
  };

  const expandSize = "lg";

  const navbarStyle = {
    borderRadius: scrollPosition > 150 ? "40px" : "0",
    border: scrollPosition > 150 ? "1px solid" : "none",
    transition: "border-radius 0.3s ease-in-out, border 0.3s ease-in-out",
  };

  const handleFAQClick = (e) => {
    handleLinkClick();
    navigate("/", { state: { scrollToFAQs: true } }); // Navigate to home route with scrollToFAQs state
    e.preventDefault();
  };

  return (
    <>
      <BootstrapNavbar
        expand={expandSize}
        className="Section MainNavbar"
        style={navbarStyle}
      >
        <Container fluid>
          <Link className="RemovingLinkStyles" to="/">
            <img className="Logo" src={LogoAquest2} alt="LogoAquest" />
          </Link>

          <BootstrapNavbar.Toggle
            className="ttt white-nav-toggle"
            style={{ color: "white" }}
            aria-controls={`offcanvasNavbar-expand-${expandSize}`}
            onClick={() => setShowOffcanvas((prev) => !prev)}
          />
          <BootstrapNavbar.Offcanvas
            show={showOffcanvas}
            onHide={handleClose}
            id={`offcanvasNavbar-expand-${expandSize}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expandSize}`}
            placement="end"
          >
            <Offcanvas.Header className="OffcanvasHeader">
              <div className="LogoandClose-Container">
                <div id={`offcanvasNavbarLabel-expand-${expandSize}`}>
                  <img
                    id="ofcanvas-NavLogo"
                    src={LogoAquest2}
                    alt="LogoAquest"
                  ></img>
                </div>
              </div>
              <button
                type="button"
                className="Nav-btn-close"
                aria-label="Close"
                onClick={handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="50px"
                  height="50px"
                >
                  <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
                </svg>
              </button>
            </Offcanvas.Header>

            <Offcanvas.Body className="OffcanvasBody">
              <Nav className="NavbarOptions">
                <Link
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  Home
                </Link>
                <Link
                  to="/services"
                  className="nav-link"
                  onClick={handleLinkClick}
                  activeClassName="active"
                >
                  Services
                </Link>
                
                <a
                  href="#faqs" // Changed href to "#faqs"
                  className="nav-link"
                  onClick={handleFAQClick} // Call handleFAQClick on click
                >
                  FAQs
                </a>

                <Link
                  to="/about"
                  className="nav-link"
                  onClick={handleLinkClick}
                  activeClassName="active"
                >
                  About Us
                </Link>
              </Nav>

              <div className="NavContactus-Sub">
                <Form className="NavContactUsBtnMain">
                  <Link
                    to="/contactUs"
                    className="nav-link NavContactUsDiv"
                    onClick={handleLinkClick}
                    activeClassName="active"
                  >
                    <button className="NavContactUsBtn">Contact Us</button>
                  </Link>
                </Form>
              </div>
            </Offcanvas.Body>
          </BootstrapNavbar.Offcanvas>
        </Container>
      </BootstrapNavbar>
    </>
  );
}

export default MyNavbar;
