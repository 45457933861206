import React, { useEffect, useState } from "react";

const TandC = () => {
  const [paddingTandC, setPadding] = useState("100px");

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const newPadding = screenWidth <= 768 ? "40px" : "100px";
      setPadding(newPadding);
    };

    // Call the function to set initial padding
    handleResize();

    // Add event listener to handle resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const HeadingTandC = {
    marginTop: "20px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "25px",
    lineHeight: "43px",
    /* identical to box height */
    display: "flex",
    alignItems: "center",
  };

  const MatterTandC = {
    marginTop: "20px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
    /* identical to box height */
    display: "flex",
    alignItems: "center",
    color: "#131414",
  };

  const TandCContainer = {
    padding: paddingTandC, // Dynamically set padding based on screen width
  };

  return (
    <>
      <div>
        <div>
          <div
            className="text-center tandc-header"
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "30px",
              lineHeight: "73px",
              color: "white",
              fontFamily: "Montserrat",
              height: "20vh",
              backgroundColor: "#204959",
              borderRadius: " 0px 0px 40px 40px",
            }}
          >
            Terms & Conditions
          </div>

          <div className="TandC-Container" style={TandCContainer}>
            <div className="TandC-Q" style={HeadingTandC}>
              Terms And Conditions Of Use
            </div>

            <div className="TandC-M" style={MatterTandC}>
              By accessing this Site, you agree to be bound by these Site Terms
              and Conditions of Use (“T&C”) and agree that you are responsible
              for compliance with any applicable local laws. If you do not agree
              with any of these terms, you are prohibited from using or
              accessing this Site. The materials contained in this Site are
              protected by applicable copyright and trademark law. You may use
              the Site only for lawful purposes. It is forbidden to use the Site
              in any manner that is unlawful or dishonest; to send, upload, or
              reuse content that does not comply with the T&C; to knowingly
              transfer any data; to send or upload material that contains
              viruses, spyware, adware, or other malicious software. It is
              forbidden to attempt to gain unauthorized access to the server on
              which the Site is stored, computer, or database related to the
              Site, as well as any unauthorized access aimed at changing, making
              derivative works of, disassembling, reverse compiling, or reverse
              engineering any part of this Site. It is forbidden to access this
              Site in order to build a similar or competitive site, no part of
              this Site may be copied, reproduced, distributed, republished,
              downloaded, or used for commercial purposes.
            </div>

            <div className="TandC-Q" style={HeadingTandC}>
              Links To Other Sites
            </div>

            <div className="TandC-M" style={MatterTandC}>
              The Site may contain third-party materials or links to other
              Sites. Links to other sites are provided on the Site for your
              information and convenience only. Aquest Global Solutions has no
              control over these sites, does not check, oversee, review, approve
              or endorse its contents. Aquest Global Solutions is not
              responsible for the availability of these sites, their contents,
              nor for any damage or harm that might be inflicted as a result of
              accessing those sites. Use of any such linked site is at the
              user’s own risk.
            </div>

            <div className="TandC-Q" style={HeadingTandC}>
              Revision & Changes
            </div>

            <div className="TandC-M" style={MatterTandC}>
              The materials appearing on the Site could include technical,
              typographical, or photographic errors. Aquest Global Solutions
              does not warrant that any of the materials on the Site are
              accurate, complete, or up-to-date. Aquest Global Solutions is
              entitled to suspend, withdraw, or modify all or some elements of
              the Site at any time without notice. Aquest Global Solutions
              reserves the right, at our sole discretion, to modify or replace
              these T&C without notice at any time. You can review the most
              current version of the T&C at any time on this page.
            </div>

            <div className="TandC-Q" style={HeadingTandC}>
              Breaches
            </div>

            <div className="TandC-M" style={MatterTandC}>
              If Aquest Global Solutions becomes aware of a breach of the T&C,
              Aquest Global Solutions may immediately implement corrective
              actions, including disabling access to the Site as well as
              removing any information, data, and content provided by the user
              on the Site at any time without notice.
            </div>

            <div className="TandC-Q" style={HeadingTandC}>
              Disclaimer
            </div>

            <div className="TandC-M" style={MatterTandC}>
              This Site is provided on an “as available” basis, we do not make
              warranties and hereby disclaim any warranties, either express or
              implied, with respect to the accuracy, adequacy, or completeness
              of the information obtained from this Site. Any reliance on the
              information on this Site is at your own risk. Aquest Global
              Solutions makes no warranties, expressed or implied, to the
              digital availability, functionality, compatibility, visibility,
              and/or comprehensibility of the Site. Any information or
              statements included on the Site shall not constitute a binding
              offer and cannot be construed as such. Aquest Global Solutions is
              not liable for damages caused by viruses and other software and/or
              that results from accessing or downloading materials that the Site
              contains. If you decide to download materials from the Site, you
              do so at your own risk. We reserve the right to change, suspend,
              or cease this Site without notice to you
            </div>

            {/* Additional sections go here with the same structure */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TandC;
