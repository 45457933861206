import React from "react";
import ContactUs from "./ContactUs/ContactUs";
import './ContactUsComponent.css';

const ContactUsComponenet = () => {
  return (
    <>
      <div  className="ContactUS-Section" >
        <div className="ContactUScontainer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-4 ContactusBody">
                <div class=" getInTouch-Contactus"> Get In Touch</div>
                <p className="AdressContactus">Reach Us Land Mark - D-MART, Plot No-12,Survey No 1009, KPHB 5th
                Phase, Kukatpally, Hyderabad, Telangana 500072.
                <br></br>Mail us: aquestglobal.com <br></br>
                Call us: (+91) 9392245045</p>
              </div>
              <div class="col">
                <iframe
                className="IframeBody"
                  title="Google Maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d237.8476518031881!2d78.3926663988316!3d17.480649052464766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb916fbe599899%3A0x5a52e722681d1410!2sBirla%20Open%20Minds%20Pre%20School%20Kukatpally!5e0!3m2!1sen!2sin!4v1708006876330!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
           
          </div>

         
        </div>
        <ContactUs />
      </div>
    </>
  );
};

export default ContactUsComponenet;



  