import Navbar from "./Components/Navbar.js";
import AboutUs from "./Components/About.js";
import HomePage from "./Components/HomePage.js";
import Services from "./Components/Services/Services.js";
import ContactUsComponenet from "./Components/ContactUsComponenet.js";
import TandC from "./Components/TandC.js";
import Footer from "./Components/Footer/Footer.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./Components/ScroolToTop.js";
function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<Services />} />
          {/* <Route path="/faq" element={<Faq />} /> */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/tandC" element={<TandC />} />

          <Route path="/contactUs" element={<ContactUsComponenet />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
