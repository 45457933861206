import React, { useState } from "react";
import "./OurValues.css";
import OurVisionImg from "../Assets/OurVisionIMG.png";
import { Slide } from "react-awesome-reveal";

const OurValues = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(0); // Initialize to 0

  const handleQuestionClick = (index) => {
    setSelectedQuestion(index);
  };

  return (
    <div>
      <div className="container">
        <div className="text-center OurValues-Heading">Our Values</div>
        <div className="row OurValues-Matter">
          <div className="col-md-6">
            <img src={OurVisionImg} alt="" />
          </div>
          <div className="col-md-6">
            <Slide direction="left">
              <div
                className={`QandAns-box ${
                  selectedQuestion === 0 ? "highlight" : ""
                }`}
                onClick={() => handleQuestionClick(0)}
              >
                <p className="Vquestion">Outperform Expectations</p>
                <p className="Vanswer">
                  We strive to produce and deliver only top-notch solutions,
                  exceeding your expectations
                </p>
              </div>
            </Slide>
            <Slide direction="right">
              <div
                className={`QandAns-box ${
                  selectedQuestion === 1 ? "highlight" : ""
                }`}
                onClick={() => handleQuestionClick(1)}
              >
                <p className="Vquestion">Customer-Centric Approach</p>
                <p className="Vanswer">
                  We prioritize understanding and addressing the unique needs
                  and concerns of our customers, ensuring their satisfaction and
                  success.
                </p>
              </div>
            </Slide>
            <Slide direction="left">
              <div
                className={`QandAns-box ${
                  selectedQuestion === 2 ? "highlight" : ""
                }`}
                onClick={() => handleQuestionClick(2)}
              >
                <p className="Vquestion">Continuous Improvement</p>
                <p className="Vanswer">
                  We are committed to continuously refining our processes,
                  products, and services to achieve excellence and stay ahead of
                  industry standards
                </p>
              </div>
            </Slide>
            <Slide direction="right">
              <div
                className={`QandAns-box ${
                  selectedQuestion === 3 ? "highlight" : ""
                }`}
                onClick={() => handleQuestionClick(3)}
              >
                <p className="Vquestion">Integrity and Transparency</p>
                <p className="Vanswer">
                  We conduct our business with the highest level of integrity
                  and transparency, fostering trust and accountability in all
                  our interactions.
                </p>
              </div>
            </Slide>

            {/* Add more question-answer pairs similarly */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
