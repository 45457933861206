import React from "react";
import "../Services/HowWeWork.css";
import { Slide } from "react-awesome-reveal";

const HowWeWork = () => {
  return (
    <div className="section Card-Container">
 <Slide direction="down">
 <div className="howWeWork-Titles">
  <p className="howWeWork-TitleOne">WORK STEPS</p>
  <p className="howWeWork-TitleTwo"> How We Work</p>
 </div>
 </Slide>
      <div className="d-flex Card-SubContainer">
      
        <div className=" ">
          <div className="cardMain-s">
            <div className="card-body">
              <div className="step">STEP 1</div>
              <h5 className="card-title">Initial Assessment</h5>
              <p className="card-text">
                Once we receive a request from you, we arrange a call to go over
                the project concepts and specifics. Based on that, we prepare an
                initial assessment (estimate)
              </p>
            </div>
          </div>
        </div>

        <div className="">
          <div className="cardMain-s">
            <div className="card-body">
              <div className="step step2">STEP 2</div>
              <h5 className="card-title">Planning & Roadmap Creation</h5>
              <p className="card-text">
                Having agreed upon the initial assessment, we prepare a detailed
                roadmap with scope, timeline, and a dedicated team
              </p>
            </div>
          </div>
        </div>

        <div className="">
          <div className="cardMain-s">
            <div className="card-body">
              <div className="step step3">STEP 3</div>
              <h5 className="card-title">Roadmap Implementation</h5>
              <p className="card-text">
                The team performs development of your application with an Agile
                approach. When the core functionality is ready, the app
                undergoes thorough testing
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="cardMain-s">
            <div className="card-body">
              <div className="step step4">STEP 4</div>
              <h5 className="card-title">Result Evaluation</h5>
              <p className="card-text">
                We help you analyze the achieved result, decide & plan on
                further development stages or improvements
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="cardMain-s">
            <div className="card-body">
              <div className="step step5">STEP 5</div>
              <h5 className="card-title">Maintenance & Expansion</h5>
              <p className="card-text">
                The team works on optimization, bug fixing and new features
                implementation
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
